<template>
  <div>
    <portal to="title-page">
      <h1>Crear landing</h1>
    </portal>
    <v-card
      class="mx-auto elevation-1"
    >
      <Loading :active.sync="isLoading" :is-full-page="false"></Loading>
      <v-card-title >
        <v-icon
          large
          left
        >
          mdi-plus
        </v-icon>
        <span class="title font-weight-light">Datos</span>
        <v-btn small class="ml-auto" @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row class="mb-4">
            <v-col cols="12" md="3" class="">
              <v-text-field
                label="Idioma"
                prepend-icon="mdi-flag"
                :value="language ? language.name : ''"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="9" class="">
              <v-text-field
                color="#f88511"
                v-model="url"
                required
                :rules="[rules.required]"
                :label="labelUrl"
                prepend-icon="mdi-web"
                
              ></v-text-field>
            </v-col>
          </v-row>
          
          <v-row class="mb-4">
            <v-col md="4" class="">
              <v-text-field
                color="#f88511"
                v-model="landing.name"
                required
                :rules="[rules.required]"
                label="Nombre"
                prepend-icon="mdi-text"
                dense
              ></v-text-field>
            </v-col>
            <v-col md="4" class="">
              <v-text-field
                color="#f88511"
                v-model="landing.title"
                required
                :rules="[rules.required]"
                label="Titulo"
                prepend-icon="mdi-format-title"
                dense
              ></v-text-field>
            </v-col>
            <v-col md="4" class="">
              <v-select
                v-model="landing.status"
                :items="[{text:'Activo', value: 'active'},{text:'Inactivo', value: 'inactive'}]"
                item-text="text"
                item-value="value"
                label="Status"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4" class="mb-3">
              <v-select
                v-model="landing.type"
                :items="[{text:'Registro', value: 'register'},{text:'Tours', value: 'tours'}]"
                item-text="text"
                item-value="value"
                prepend-icon="mdi-form-select"
                label="Tipo de landing page"
                @change="changeType"
                dense
              ></v-select>
            </v-col>
            <v-col v-if="landing.type == 'tours'" md="12" class="">
              <v-combobox
                v-model="landing.tours"
                :items="tours"
                label="Tours"
                item-text="name"
                item-value="id"
                prepend-icon="mdi-format-list-numbered"
                return-object
                multiple
                dense
                chips
              ></v-combobox>
            </v-col>
            <v-col md="12" class="py-0">
              <v-textarea
                class=""
                v-model="landing.metadescription"
                label="Metadescripcion"
                rows="2"
                counter
                hint="Se recomienda maximo 160 caracteres"
                prepend-icon="mdi-comment"
              ></v-textarea>
            </v-col>
            <v-col md="12" class="py-0">
              <p>Descripcion</p>
              <vue-editor
                :class="landing.description != null && landing.description == '' ? 'error' : ''"
                color="#f88511"
                v-model="landing.description"
                :editor-toolbar="customToolbar"
                auto-grow
                outlined
                rows="1"
                row-height="15"
                dense
              ></vue-editor>
            </v-col>
            <v-col md="12" class="my-4">
              <v-btn
                :disabled="disableBtnAdd"
                color="primary"
                class="mr-4"
                @click="validateForm('add')"
              >
                <v-icon>mdi-content-save</v-icon> Agregar
              </v-btn>
              <!-- <v-btn
                :disabled="disableBtnAdd"
                color="success"
                class="mr-4"
                @click="validateForm('images')"
              >
                <v-icon>mdi-image-multiple</v-icon> Continuar con imagenes
              </v-btn>
              <v-btn
                :disabled="disableBtnAdd"
                class="mr-4"
                @click="validateForm('rates')"
              >
                <v-icon>mdi-cart-plus</v-icon> Continuar con Extras
              </v-btn> -->
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Language from "@/models/Language.js";
import Landing from "@/models/Landing.js";
import Tour from "@/models/Tour.js";
export default {
  name:"AdminCMSLandingCreate",
  data() {
    return {
      landingModel:new Landing,
      validFormInit:true,
      tours:null,
      validForm:true,
      isLoading:true,
      language:null,
      url:'',
      languageModel:new Language,
      tourModel:new Tour,
      landing: {
        name: '',
        description: null,
        metadescription: "",
        status: 'inactive',
        type: 'register', // register | tours
      },
      rules: {
        required: (v) => !!v || "El campo es obligatorio"
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      snackbar: {
        show: false,
        message: "",
        color: "",
      },
    }
  },
  mounted() {
    this.languageModel.getAll().onSnapshot(snap => {
      this.languages = {};
      snap.forEach( doc => {
        this.languages[doc.id] = doc.data();
      })
      this.language = this.languages.es;
      this.landing.language = 'es';
      this.isLoading = false;
    })
  },
  created () {
    this.$store.commit('SET_LAYOUT', 'admin')
  },
  methods:{
    changeType() {
      if (this.landing.type == 'tours' && this.tours === null) {
        this.isLoading = true
        this.tourModel.all().onSnapshot((qSnap) => {
          this.tours = [];
          this.isLoading = false
          qSnap.forEach((doc) => {
            this.tours.push({
              id:doc.data().id,
              name:doc.data().name,
            });
          });
        })
      }
    },
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'green';
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'red';
    },
    validateForm(action) {
      this.validFormInit = false;
      this.landing.description = this.landing.description == null ? '' : this.landing.description;
      if (this.$refs.form.validate()) {
        //let loading = this.$loading.show();
        this.isLoading = true;
        //console.log('this.tourUrl, this.language.id', this.tourUrl, this.language.id);
        let prefix = this.language.id == 'es' ? '' : `/${this.language.id}`;
        let fullUrl = `${prefix}/landings/${this.url}`;
        this.landingModel.findUrl(fullUrl, this.language.id).get().then(snap => {
          if (snap.empty) {
            this.landingModel.add(this.landing, this.language).then((doc) => {
              
              doc.collection('landingUrls')
              .doc(doc.id+this.language.id)
              .set({
                landingId:doc.id,
                fullUrl:fullUrl,
                url:this.url,
                deleted:0,
                language:this.language.id
              }).then(() => {
                //loading.hide();
                this.showSnackbarSuccess('La landing page se ha agregado correctamente')
                if (action == 'images') {
                  this.$router.push({path:`/backend/admin/cms/landings/${doc.id}/images`})
                } else if(action == 'rates') {
                  this.$router.push({path:`/backend/admin/cms/landings/${doc.id}/extras`})
                } else {
                  this.$router.push({path:`/backend/admin/cms/landings`})
                }
                
              })
            })
            .catch(error => {
              console.log(error);
              this.isLoading = false;
              this.showSnackbarError('Error al crear la landing page');
            })
            this.isLoading = false;
          } else {
            this.showSnackbarError('La url "'+this.url+ '" ya esta registrada, ingrese otra');
            this.isLoading = false;
          }
        });
      }
    },
  },
  computed:{
    labelUrl() {
      if (this.url) {
        const lang = this.language.id == 'es' ? '' : this.language.id + `/`
        return `Url en `
          +(this.language ? this.language.name : '')
          + `: ${process.env.VUE_APP_API_URL}/landings/`
          + lang  +this.url
      }
      return `Url en ` +(this.language ? this.language.name : '')
    },
    disableBtnAdd() {
      if (this.validFormInit) {
        return false;
      }
      console.log('this.landing.description', this.landing.description);
      
      if (!this.landing.description && !this.validFormInit) {
        return true;
      }
      return false;
    }
  }
}
</script>